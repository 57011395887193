import { createSlice } from '@reduxjs/toolkit';

import { constants } from '../../../helpers';
import { userLogin } from '../../services/auth';
import { getAdmin } from '../../services/account';


const walletInitialDetails = {
  id: "",
  isConnect: false,
  address: "",
};

const initialState = {
  isloggedin: false,
  accessToken: '',
  userId: '',
  username: '',
  email: '',
  actionType: '',
  password: '',
  countryCode: '',
  phoneNumber: '',
  enableNotifications: false,
  acceptTerms: false,
  wallet: walletInitialDetails,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /** Clearing redux state */
    clearReduxState() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },

    setForgotPasswordEmail(state, action) {
      state.email = action.payload.email;
      state.actionType = constants.API_TYPE.FORGOT_PASSWORD;
    },

    setWalletDetails(state, action) {
      state.wallet = {
        ...state.wallet,
        ...(action.payload || {}),
      };
    },

    setActionType(state, action) {
      state.actionType = action.payload.type;
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(userRegistration.fulfilled, (state, action) => {
    //   state.userId = action.payload?.data?._id || "";
    //   state.email = action.payload?.data?.email || "";
    //   state.username = action.meta?.arg?.userName || "";
    //   state.password = action.meta?.arg?.password || "";
    //   state.countryCode = action.meta?.arg?.countryCode || "";
    //   state.phoneNumber = action.meta?.arg?.fullMobileNo || "";
    //   state.enableNotifications =
    //     action.meta?.arg?.scheduleNotification || false;
    //   state.acceptTerms = true;
    // });

    // builder.addCase(verifyOtp.fulfilled, (state, action) => {
    //   if (state.actionType === constants.API_TYPE.FORGOT_PASSWORD) {
    //     state.userId = action.payload?.data?.userId || "";
    //   } else {
    //     state.isloggedin = true;
    //     state.accessToken = action.payload?.data?.accessToken || "";
    //   }
    // });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isloggedin = true;
      state.accessToken = action.payload?.data?.accessToken || '';
      state.userId = action.payload?.data?._id || '';
      state.email = action.payload?.data?.email || '';
      state.username = action.payload?.data?.firstName || '';
      const wallet = {
        isConnect: !!action.payload?.data?.isWalletConnect,
        address: action.payload?.data?.walletAddress || "",
      };
      state.wallet = wallet;
    });

    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.username = action.payload?.data?.firstName || '';
    });
  }
});

export const {
  clearReduxState,
  setForgotPasswordEmail,
  setWalletDetails,
  setActionType
} = authSlice.actions;
export default authSlice.reducer;
