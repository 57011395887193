import { DateFormat } from '../common/third-party-instances';

export function startOfDaytime(date) {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  return start;
}

export function endOfDaytime(date) {
  const end = new Date(date);
  end.setHours(23, 59, 59, 999);
  return end;
}

export function tableDateFormat(date, format = 'MMM d, yyyy') {
  return DateFormat(new Date(date), format);
}

export function selectDateFormate(date,format="yyyy-MM-dd"){
  return DateFormat(new Date(date), format);
}
export function dateTimeFormate(date,format='MMM d, yyyy hh:mm a'){
  return DateFormat(new Date(date), format);
}

export function timeFormate(date,format='HH:mm'){
  return DateFormat(new Date(date), format);
}

export function convertUTCDateToLocalDate(timing) {
  const timeZone = new Date().getTimezoneOffset()        
  let localTimeZone=0;
  if(timeZone < 0){
    return localTimeZone = timing - new Date().getTimezoneOffset()*60*1000
  }else{
     return localTimeZone = timing + new Date().getTimezoneOffset()*60*1000
  }
}
