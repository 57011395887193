import { useNavigate } from 'react-router-dom';
import { Logo } from '../../../assets/images/images';
import Dropdown from 'react-bootstrap/Dropdown';
import { constants } from '../../../helpers';
import { postDataApi } from '../../../api/methods';
import { useAppDispatch, useAppSelector } from '../../custom-hooks';
import { useCallback } from 'react';
import { clearReduxState } from '../../../redux/features/authSlice';
import { successAlert } from '../../../utils';

function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isloggedin, username } = useAppSelector((state) => state.auth);

  const handleClick = useCallback(
    (path) => {
      if (path === 'profile') {
        navigate(constants.BROWSER_ROUTES.My_PROFILE);
      } else if (path === 'changePass') {
        navigate(constants.BROWSER_ROUTES.CHANGE_PASSWORD);
      } else {
        postDataApi({
          path: constants.API_PATHS.LOGOUT_USER,
          data: {
            callback: (response) => {
              if (response?.statusCode === 200) {
                dispatch(clearReduxState());
                if (response.message) {
                  window.location.href = constants.BROWSER_ROUTES.LOGINPATH;
                  // navigate(constants.BROWSER_ROUTES.LOGINPATH)
                  successAlert(response.message);
                }
              }
            }
          }
        });
      }
    },
    [dispatch]
  );

  return (
    <header className="main-header">
      <div className="logo">
        <img alt="icons" src={Logo} />
      </div>
      {isloggedin && (
        <div className="ml-auto right-icons">
          <div>
            <div>
              <i className="bell-icon"></i>
            </div>
          </div>

          <div>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">Hi {username}</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleClick('profile')}>My Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => handleClick('changePass')}>
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleClick('logout')}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
