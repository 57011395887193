import React, { useCallback, useState } from 'react';
import 'bootstrap/scss/bootstrap.scss';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './redux/store';
import AppRoutes from './router';
import './style.scss';
import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import {
  goerli,
  mainnet
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  // walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import config from "./Config";
import { useAppSelector } from "./common/custom-hooks";
import { getWalletIcon } from "./utils";

const projectId = config.WALLET_PROJECT_ID;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    goerli,
    mainnet
  ],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: "Select wallet to connect",
    wallets: [
      metaMaskWallet({ projectId, chains }),
      rainbowWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: "web3connect" }),
      // walletConnectWallet({ projectId, chains }),
      injectedWallet({ chains }),
    ],
  },
]);


const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const CustomAvatar = ({ address, ensImage, size }) => {
  const { wallet } = useAppSelector((state) => state.auth);
  const WalletIcon = getWalletIcon(wallet.id);

  return WalletIcon ? (
    <img
      src={WalletIcon}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
      alt={wallet.id || "Wallet"}
    />
  ) : (
    <div
      style={{
        backgroundColor: "red",
        borderRadius: 999,
        height: size,
        width: size,
      }}
    >
      :^)
    </div>
  );
};

function App(history) {
    // eslint-disable-next-line no-unused-vars
    const [reRenderCount, forceReRender] = useState(0);

    const handleReRenderCount = useCallback(() => {
      forceReRender((pCount) => (pCount || 0) + 1);
    }, []);

  return (
      <Provider store={store}>
        <ToastContainer />
        <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          // avatar={null}
          avatar={CustomAvatar}
          modalSize="compact"
          // coolMode={true}
          chains={chains}
          appInfo={{
            appName: "web3connect",
            // disclaimer: Disclaimer
          }}
          // theme={midnightTheme()}
          theme={{
            lightMode: lightTheme(),
            darkMode: darkTheme(),
          }}
        >
          <AppRoutes history={history.history} onForceReRender={handleReRenderCount} />
        </RainbowKitProvider>
      </WagmiConfig>
      </Provider>
  );
}

export default App;
