export function copyObject(data) {
    return Object.assign({}, data);
}

export function deepCopyObject(data) {
    return JSON.parse(JSON.stringify(data));
}

export function isObject(obj) {
    if (obj && !!Object.keys(obj).length) {
        return true;
    }
    return false;
}
