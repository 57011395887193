import React from 'react';
import { constants } from '../../../helpers';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

const SideBar = (props) => {
  const { children, pathname } = props;
  const navigate = useNavigate();
  const Id = pathname.split('/')[2]

  return (
    <div className="main">
      <aside>
        <div>
          <ul>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.DASHBOARD)}
                className={constants.BROWSER_ROUTES.DASHBOARD === pathname ? 'active' : ''}
              >
                <i className='block-icon mr8'></i> Dashboard
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.USER_MANAGEMENT)}
                className={(constants.BROWSER_ROUTES.USER_MANAGEMENT === pathname || pathname === `/user-details/${Id}`) ? 'active' : ''}
              >
                <i className='block-icon mr8'></i>
                User
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.CATEGORY_MANAGEMENT)}
                className={
                  (constants.BROWSER_ROUTES.CATEGORY_MANAGEMENT === pathname ||
                    pathname === constants.BROWSER_ROUTES.ADD_CATEGORY ||
                    pathname === `/view-category/${Id}` ||
                    pathname === `/edit-category/${Id}` ||
                    pathname === constants.BROWSER_ROUTES.ADD_SUBCATEGORY ||
                    pathname === `/view-subcategory/${Id}` ||
                    pathname === `/edit-subcategory/${Id}`) ? 'active' : ''
                }
              >
                <i className='folder-icon mr8'></i>
                Category
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.COLLECTIVE_MANAGEMENT)}
                className={
                  (pathname === constants.BROWSER_ROUTES.COLLECTIVE_MANAGEMENT ||
                    pathname === `/view-collective/${Id}`) ? 'active' : ''
                }><i className='block-icon mr8'></i> Collective</a>
            </li>
            <li>
              {/* <Accordion >
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => navigate(constants.BROWSER_ROUTES.CONTENT_MANAGEMENT)} className={
                constants.BROWSER_ROUTES.CONTENT_MANAGEMENT === pathname ? 'active' : ''
              }> <i className='block-icon mr8'></i>  Content</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <a href='javascript:;'>About Us</a>
                      <a href='javascript:;'>Terms of Use</a>
                      <a href='javascript:;'>FAQ</a>
                      <a href='javascript:;'>Privacy Policy</a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.CONTENT_MANAGEMENT)}
                className={
                  (pathname === constants.BROWSER_ROUTES.CONTENT_MANAGEMENT ||
                    pathname=== constants.BROWSER_ROUTES.ADD_CONTENT ||
                    pathname === `/view-content/${Id}` ||
                    pathname === `/edit-content/${Id}`)  ? 'active' : ''
                }
              > <i className='block-icon mr8'></i>Content</a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.NOTIFICATION_MANAGEMENT)}
                className={
                  (pathname === constants.BROWSER_ROUTES.NOTIFICATION_MANAGEMENT ||
                    pathname === constants.BROWSER_ROUTES.ADD_NOTIFICATION ||
                    pathname === `/view-notification/${Id}` ||
                    pathname === `/edit-notification/${Id}`
                  ) ? 'active' : ''
                }
              > <i className='block-icon mr8'></i> Notification</a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.REFERRAL_MANAGEMENT)}
                className={
                  (pathname === constants.BROWSER_ROUTES.REFERRAL_MANAGEMENT ||
                    pathname === constants.BROWSER_ROUTES.ADD_REFERRAL ||
                    pathname === `/view-referral/${Id}`) ? 'active' : ''
                }
              ><i className='block-icon mr8'></i>  Referral</a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => navigate(constants.BROWSER_ROUTES.EVENT_MANAGEMENT)}
                className={
                  (pathname === constants.BROWSER_ROUTES.EVENT_MANAGEMENT ||
                   pathname === constants.BROWSER_ROUTES.ADD_EVENT || 
                   pathname === `/edit-event/${Id}` || 
                   pathname === `/view-event/${Id}` )  ? 'active' : ''
                }
              > <i className='block-icon mr8'></i> Events CMS</a>
            </li>
          </ul>
        </div>
      </aside>

      <div className="right-side">
        <div className="container">{children}</div>
      </div>
    </div>
  );
};

export default SideBar;
