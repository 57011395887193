import { createAsyncThunk } from '@reduxjs/toolkit';

import { postDataApi } from '../../api/methods';
import { constants } from '../../helpers';

export const verifyOtp = createAsyncThunk(constants.API_PATHS.VERIFY_OTP, (payload) =>
  postDataApi({ path: constants.API_PATHS.VERIFY_OTP, data: payload })
);

export const userLogin = createAsyncThunk(constants.API_PATHS.LOGIN, (payload) =>
  postDataApi({ path: constants.API_PATHS.LOGIN, data: payload })
);

export const forgotPassword = createAsyncThunk(constants.API_PATHS.FORGOT_PASSWORD, (payload) =>
  postDataApi({ path: constants.API_PATHS.FORGOT_PASSWORD, data: payload })
);

export const resetPassword = createAsyncThunk(constants.API_PATHS.RESET_PASSWORD, (payload) =>
  postDataApi({ path: constants.API_PATHS.RESET_PASSWORD, data: payload })
);
