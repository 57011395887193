// import { DateFormat, SubDays } from '../common/third-party-instances';
const WALLET_ID = {
  META_MASK: "metaMask",
  COINBASE_WALLET: "coinbaseWallet",
  RAINBOW: "walletConnectLegacy",
  WALLET_CONNECT: "",
};

const constants = {
  BROWSER_ROUTES: {
    LOGINPATH: '/',
    DASHBOARD: '/dashboard',
    FORGOT_PASSWORD: '/forgot-password',
    OTP_VERIFICATION: '/otp-verification',
    RESET_PASSWORD: '/reset-password',
    My_PROFILE: '/myprofile',
    CHANGE_PASSWORD: '/changepassword',
    USER_MANAGEMENT: '/user-management',
    USER_DETAILS: '/user-details/:id',
    CATEGORY_MANAGEMENT: '/category-management',
    ADD_CATEGORY: '/add-category',
    EDIT_CATEGORY: '/edit-category/:id',
    ADD_SUBCATEGORY: '/add-subcategory',
    EDIT_SUBCATEGORY: '/edit-subcategory/:id',
    VIEW_CATEGORY: '/view-category/:id',
    VIEW_SUBCATEGORY: '/view-subcategory/:id',
    COLLECTIVE_MANAGEMENT: '/collective-management',
    VIEW_COLLECTIVE: '/view-collective/:id',
    CONTENT_MANAGEMENT: '/content-management',
    ADD_CONTENT: '/add-content',
    VIEW_CONTENT: '/view-content/:id',
    EDIT_CONTENT: '/edit-content/:id',
    NOTIFICATION_MANAGEMENT: '/notification-management',
    ADD_NOTIFICATION: '/add-notification',
    EDIT_NOTIFICATION: '/edit-notification/:id',
    VIEW_NOTIFICATION: '/view-notification/:id',
    REFERRAL_MANAGEMENT: '/referral-management',
    ADD_REFERRAL: '/add-referral',
    VIEW_REFERRAL: '/view-referral/:id',
    EVENT_MANAGEMENT: '/event-management',
    ADD_EVENT: '/add-event',
    UPDATE_EVENT:'/edit-event/:id',
    VIEW_EVENT: '/view-event/:id'
  },

  LOADER: {
    TYPE: {
      FULL_SCREEN: 'FULL_SCREEN',
      TABLE: 'TABLE'
    }
  },

  API_PATHS: {
    VERIFY_OTP: 'admin/verify-otp',
    LOGIN: 'admin/login',
    FORGOT_PASSWORD: 'admin/forgot-password',
    RESET_PASSWORD: 'admin/reset-password',
    GET_USER_PROFILE: 'admin/profile',
    UPDATE_USER_PROFILE: 'admin/profile',
    CHANGE_PASSWORD: 'admin/change-password',
    LOGOUT_USER: 'admin/logout',
    GET_USER_LIST: 'admin/users',
    GET_USER_DETAILS: 'admin/users/',
    CHANGE_USER_STATUS: 'admin/users/status',
    GET_CATEGORY_LIST: 'categories',
    ADD_CATEGORY: 'categories',
    GET_CATEGORY_DETAILS: 'categories/',
    UPDATE_CATEGORY: 'categories',
    REMOVE_CATEGORY: 'categories/status',
    GET_SUBCATEGORY_LIST: 'sub-categories',
    ADD_SUBCATEGORY: 'sub-categories',
    UPDATE_SUBCATEGORY: 'sub-categories',
    GET_SUBCATEGORY_DETAILS: 'sub-categories/',
    REMOVE_SUBCATEGORY: 'sub-categories/status',
    GET_NOTIFICATION_LIST: 'admin/notificationList',
    GET_NOTIFICATION_DETAILS: 'admin/notification/',
    ADD_NOTIFICATIONS: 'admin/addNotification',
    GET_NOTIFICATION_URL_LIST: 'admin/notification-url-list',
    EDIT_NOTIFICATION: 'admin/update-notification',
    GET_COLLECTIVE_LIST: 'admin/collectiveList',
    GET_COLLECTIVE_DETAILS: 'admin/collective-details',
    GET_CONTENT_LIST: 'admin/contentList',
    ADD_STATIC_CONTENT: 'admin/contents',
    GET_CONTENT_DETAILS: 'admin/contents/',
    UPDATE_CONTENT: 'admin/contents',
    REMOVE_CONTENT: 'admin/contents/',
    GET_REFERRAL_LIST: 'admin/referral',
    ADD_REFERRAL: 'admin/add-referral',
    REMOVE_REFERRAL: 'admin/referral/status',
    GET_REFFERAL_DETAILS: 'admin/referral/',
    GET_REFERRAL_USER_LIST: 'admin/referral-list',
    GET_EVENTS: 'events',
    CREATE_EVENT: 'admin/create-event',
    UPDATE_EVENT: 'admin/update-event',
    EVENT_DETAILS:'admin/',
    GET_DASHBOARD: 'admin/dashboard',
    GET_DASHBOARD_LISTS: 'admin/dashboard-list',
    UPDATE_FEATURED_COLLECTIVES: 'admin/collective-update',
    GET_CREATED_COLLECTED_COLLECTIVE_LIST:'admin/created-collected',
    CONNECT_WALLET:'admin/wallet',
    GAS_CREDIT:'admin/gas-credit',
  },
  API_TYPE: {
    REGISTER: 'REGISTER',
    FORGOT_PASSWORD: 'FORGET-PASSWORD',
    VERIFYOTP: 'VERIFY-OTP',
    LOGIN: 'LOGIN'
  },
  PAGINATION_DATA: Object.freeze({
    list: [],
    total: 0,
    pageNo: 1
  }),

  PER_PAGE_RECORDS: 10,

  PAGE_PER_RECORD_OPTIONS: [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 }
  ],
  DECIMAL_LIMIT: {
    CONTRIBUTION: 18,
    PERCENTAGE: 2,
    DISPLAY: 7,
    CONTRIBUTION_GOAL:3
  },
  GAMIFICATION_THRESHOLD: {
    LEVEL1: 0.00001,
    LEVEL2: 0.00002,
    LEVEL3: 0.00003,
    LEVEL4: 0.00004,
    LEVEL5: 0.00005,
  },
  SORTING: {
    DESC: -1,
    ASC: 1
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
  },
  WALLET: {
    LIST: [
      { id: WALLET_ID.META_MASK, name: "Metamask", icon: "metamask-icon" },
      {
        id: WALLET_ID.RAINBOW,
        name: "Rainbow",
        icon: "rainbow-icon",
      },
      {
        id: WALLET_ID.COINBASE_WALLET,
        name: "Coinbase",
        icon: "coinbase-icon",
      },
      // {
      //   id: WALLET_ID.WALLET_CONNECT,
      //   name: "Wallet Connect",
      //   icon: "walletconnect-icon",
      // },
    ],
    ID: {
      META_MASK: "metaMask",
      COINBASE_WALLET: "coinbaseWallet",
      RAINBOW: "walletConnectLegacy",
      WALLET_CONNECT: "",
    },
  },
};

export default constants;
