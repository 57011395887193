import { lazy } from 'react';

import { constants } from '../helpers';

// Instead of regular import statements, we will use the above approach for lazy loading
const LoginPage = lazy(() => import('../pages/login'));
const ForgotPasswordPage = lazy(() => import('../pages/forgotpassword'));
const OtpVerificationPage = lazy(() => import('../pages/otp-verification'));
const ResetPasswordPage = lazy(() => import('../pages/reset-password'));
const DashboardPage = lazy(() => import('../pages/dashboard'));
const MyProfilePage = lazy(() => import('../pages/myprofile'));
const ChangePasswordPage = lazy(() => import('../pages/changepassword'));
const UserManagementPage = lazy(() => import('../pages/user-management/modules/userlist'));
const UserDetailsPage = lazy(() => import('../pages/user-management/modules/user-details'));
const CategoryManagementPage = lazy(() =>
  import('../pages/category-management/modules/category-list')
);
const AddCategoryPage = lazy(() => import('../pages/category-management/modules/add-category'));
const AddSubCategoryPage = lazy(() => import('../pages/category-management/modules/add-subcategory'));
const EditSubCategoryPage = lazy(() => import('../pages/category-management/modules/add-subcategory'));
const ViewCategoryPage = lazy(() => import('../pages/category-management/modules/view-category'));
const ViewSubCategoryPage = lazy(() => import('../pages/category-management/modules/view-subcategory'));
const CollectiveManagementPage = lazy(() => import('../pages/collective-management/modules/collective-list'));
const ViewCollectivePage = lazy(() => import('../pages/collective-management/modules/view-collective'));
const ContentManagementPage = lazy(() => import('../pages/content-management/modules/content-list'));
const AddContentPage = lazy(() => import('../pages/content-management/modules/add-content'));
const ViewContentPage = lazy(() => import('../pages/content-management/modules/view-content'));
const NotificationManagementPage =lazy(()=>import('../pages/notification-management/modules/notification-list'));
const AddNotificationPage =lazy(()=>import('../pages/notification-management/modules/add-notification'));
const ViewNotificationPage =lazy(()=>import('../pages/notification-management/modules/view-notification'));
const ReferralManagementPage=lazy(()=>import('../pages/referral-management/modules/referral-list'));
const AddReferralPage=lazy(()=>import('../pages/referral-management/modules/add-referral'))
const ViewReferralPage=lazy(()=>import('../pages/referral-management/modules/view-referral'))
const EventManagementPage=lazy(()=>import('../pages/event-management/modules/event-list'));
const AddEventPage=lazy(()=>import('../pages/event-management/modules/add-update-view-event'));

const NotFoundPage = lazy(() => import('../pages/not-found'));

// const BookmarksPage = lazy(() => import("../pages/bookmarks"));
// const ViewCollectivePage = lazy(() => import("../pages/view-collective"));
// const AddCollectivePage = lazy(() => import("../pages/add-collective"));
// const SomeOneProfilePage = lazy(() => import("../pages/someone-profile"));

const PRIVATE_ROUTES = [
  {
    name: 'myprofile',
    path: constants.BROWSER_ROUTES.My_PROFILE,
    Component: MyProfilePage
  },
  {
    name: 'changepassword',
    path: constants.BROWSER_ROUTES.CHANGE_PASSWORD,
    Component: ChangePasswordPage
  },
  {
    name: 'dashboard',
    path: constants.BROWSER_ROUTES.DASHBOARD,
    Component: DashboardPage
  },
  {
    name: 'user-management',
    path: constants.BROWSER_ROUTES.USER_MANAGEMENT,
    Component: UserManagementPage
  },
  {
    name: 'use-details',
    path: constants.BROWSER_ROUTES.USER_DETAILS,
    Component: UserDetailsPage
  },
  {
    name: 'category-management',
    path: constants.BROWSER_ROUTES.CATEGORY_MANAGEMENT,
    Component: CategoryManagementPage
  },
  {
    name: 'add-category',
    path: constants.BROWSER_ROUTES.ADD_CATEGORY,
    Component: AddCategoryPage
  },
  {
    name: 'edit-category',
    path: constants.BROWSER_ROUTES.EDIT_CATEGORY,
    Component: AddCategoryPage
  },
  {
    name: 'add-subcategory',
    path: constants.BROWSER_ROUTES.ADD_SUBCATEGORY,
    Component: AddSubCategoryPage
  },
  {
    name: 'edit-subcategory',
    path: constants.BROWSER_ROUTES.EDIT_SUBCATEGORY,
    Component: AddSubCategoryPage
  },
  {
    name: 'view-category',
    path: constants.BROWSER_ROUTES.VIEW_CATEGORY,
    Component: ViewCategoryPage
  },{
    name: 'view-subcategory',
    path: constants.BROWSER_ROUTES.VIEW_SUBCATEGORY,
    Component: ViewSubCategoryPage
  },
  {
    name: 'notification-management',
    path: constants.BROWSER_ROUTES.NOTIFICATION_MANAGEMENT,
    Component: NotificationManagementPage
  },
  {
    name: 'add-notification',
    path: constants.BROWSER_ROUTES.ADD_NOTIFICATION,
    Component: AddNotificationPage
  },
  {
    name: 'edit-notification',
    path: constants.BROWSER_ROUTES.EDIT_NOTIFICATION,
    Component: AddNotificationPage
  },
  {
    name: 'view-notification',
    path: constants.BROWSER_ROUTES.VIEW_NOTIFICATION,
    Component: ViewNotificationPage
  },
  {
    name: 'collective-management',
    path: constants.BROWSER_ROUTES.COLLECTIVE_MANAGEMENT,
    Component: CollectiveManagementPage
  },
  {
    name: 'view-collective',
    path: constants.BROWSER_ROUTES.VIEW_COLLECTIVE,
    Component: ViewCollectivePage
  },
  {
    name: 'content-management',
    path: constants.BROWSER_ROUTES.CONTENT_MANAGEMENT,
    Component: ContentManagementPage
  },
  {
    name: 'view-content',
    path: constants.BROWSER_ROUTES.VIEW_CONTENT,
    Component: ViewContentPage
  },
  {
    name: 'add-content',
    path: constants.BROWSER_ROUTES.ADD_CONTENT,
    Component: AddContentPage
  },
  {
    name: 'edit-content',
    path: constants.BROWSER_ROUTES.EDIT_CONTENT,
    Component: AddContentPage
  },
  {
    name: 'referral-management',
    path: constants.BROWSER_ROUTES.REFERRAL_MANAGEMENT,
    Component: ReferralManagementPage
  },
  {
    name: 'add-referral',
    path: constants.BROWSER_ROUTES.ADD_REFERRAL,
    Component: AddReferralPage
  },
  {
    name: 'view-referral',
    path: constants.BROWSER_ROUTES.VIEW_REFERRAL,
    Component: ViewReferralPage
  },
  {
    name: 'event-management',
    path: constants.BROWSER_ROUTES.EVENT_MANAGEMENT,
    Component: EventManagementPage
  },
  {
    name: 'add-event',
    path: constants.BROWSER_ROUTES.ADD_EVENT,
    Component: AddEventPage
  },
  {
    name: 'update-event',
    path: constants.BROWSER_ROUTES.UPDATE_EVENT,
    Component: AddEventPage
  },
  {
    name: 'view-event',
    path: constants.BROWSER_ROUTES.VIEW_EVENT,
    Component: AddEventPage
  },
];

const PUBLIC_ROUTES = [
  {
    name: 'not-found',
    path: '*',
    Component: NotFoundPage
  },
  
];

const COMMON_ROUTES = [
  {
    name: 'login',
    path: constants.BROWSER_ROUTES.LOGINPATH,
    Component: LoginPage
  },
  {
    name: 'forgotpassward',
    path: constants.BROWSER_ROUTES.FORGOT_PASSWORD,
    Component: ForgotPasswordPage
  },
  {
    name: 'otp-verification',
    path: constants.BROWSER_ROUTES.OTP_VERIFICATION,
    Component: OtpVerificationPage
  },
  {
    name: 'reset-passward',
    path: constants.BROWSER_ROUTES.RESET_PASSWORD,
    Component: ResetPasswordPage
  }
];

function getRoutes(isloggedin) {
  if (isloggedin) {
    return [...PRIVATE_ROUTES, ...PUBLIC_ROUTES];
  } else {
    return [...PUBLIC_ROUTES, ...COMMON_ROUTES];
  }
}

export default getRoutes;
