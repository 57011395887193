import { createAsyncThunk } from '@reduxjs/toolkit';

import { putDataApi, postDataApi, getDataApi } from '../../api/methods';
import { constants } from '../../helpers';

export const getAdmin = createAsyncThunk(constants.API_PATHS.GET_USER_PROFILE, (payload) =>
  getDataApi({ path: constants.API_PATHS.GET_USER_PROFILE, data: payload })
);

export const updateAdmin = createAsyncThunk(constants.API_PATHS.UPDATE_USER_PROFILE, (payload) =>
  putDataApi({ path: constants.API_PATHS.UPDATE_USER_PROFILE, data: payload })
);

export const changePassword = createAsyncThunk(constants.API_PATHS.CHANGE_PASSWORD, (payload) =>
  postDataApi({ path: constants.API_PATHS.CHANGE_PASSWORD, data: payload })
);
