import React from 'react';
import Header from '../header';
import SideBar from '../sidebar';
import { useAppSelector } from '../../custom-hooks';
import { useLocation } from 'react-router-dom';

const Layout = (props) => {
  const { children } = props;
  const location = useLocation();
  const pathname = location.pathname;
  
  const checkIfLoginPage = () => {
    if (pathname === '/') {
      return {
        commonLayout: false
      };
    } else if (pathname === '/forgot-password') {
      return {
        commonLayout: false
      };
    } else if (pathname === '/otp-verification') {
      return {
        commonLayout: false
      };
    } else if (pathname === '/reset-password') {
      return {
        commonLayout: false
      };
    }
    return {
      commonLayout: true
    };
  };
  const { commonLayout } = checkIfLoginPage();

  return (
    <>
      <Header />
      {commonLayout === true ? <SideBar children={children} pathname={pathname} /> : children}
    </>
  );
};

export default Layout;
