const config = {
  // WALLET_PROJECT_ID : "eb377f8878487f35be77ce714ecebe12"
};

Object.keys(process.env).forEach((value) => {
  if (value.startsWith('REACT_APP_')) {
    config[value.slice(10)] = process.env[value] || '';
  }
});

export default config;
