/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

let timer = null;

function useHandleCalls(fetchData, deps = []) {
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fetchData();
    }, 500);
  }, [...deps]);
}

export default useHandleCalls;
