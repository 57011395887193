import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isTableLoading: false,
  isPageRefresh: false,
  modal: {
    // openedModalType: "SetNewPassword"
    // openedModalType: "Wallet",
    openedModalType: ''
  },
  wallet: {
    isConnect: false,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    /** Setting loader state */
    setIsLoader(state, action) {
      state.isLoading = action.payload;
    },
    setIsTableLoader(state, action) {
      state.isTableLoading = action.payload;
    },
    onToggleModal(state, action) {
      state.modal.openedModalType = action.payload?.modalType || '';
    },
    setIsWalletConnect(state, action) {
      const wallet = {
        isConnect: !!action.payload?.isConnect,
      };
      state.wallet = wallet;
    },
    setIsPageRefresh(state, action) {
      state.isPageRefresh = action.payload;
    }
  }
});

export default commonSlice.reducer;
export const { setIsLoader, setIsTableLoader, onToggleModal, setIsPageRefresh,setIsWalletConnect } =
  commonSlice.actions;
