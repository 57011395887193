import React from "react";
import { copyObject } from '../../utils';

const initialSortingFilterDetail = {
    sortBy: "",
    sortOrder: 0,
};


export function useSortingFilters(filters) {
    const [sortingFilters, setSortingFilters] = React.useState(
        copyObject(filters || initialSortingFilterDetail),
    );

    const handleSortingFilterOnChange = React.useCallback((filters) => {
        setSortingFilters(filters);
    }, []);

    return { sortingFilters, handleSortingFilterOnChange };
}
