// We'll use redux-logger just as an example of adding another middleware
import { combineReducers } from 'redux';
// import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';

import { configureStore } from '@reduxjs/toolkit';

import authSlice from './features/authSlice';
import commonSlice from './features/commonSlice';

const persistConfig = {
  key: 'root',
  storage: storageSession
};

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authSlice),
  common: commonSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/clearReduxState') {
    // this applies to all keys defined in persistConfig(s)
    storageSession.removeItem('persist:root');
    state = {};
  }
  return appReducer(state, action);
};

export var store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
    // if (config.ENV === 'dev') {
    //   return getDefaultMiddleware().concat(logger);
    // } else {
    //   return getDefaultMiddleware();
    // }
  }
});

export const persistor = persistStore(store);
