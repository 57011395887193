import CoinbaseIcon from "../assets/images/icons/coinbase-icon.svg";
import MetaMaskIcon from "../assets/images/icons/metamask-icon.svg";
import RainbowIcon from "../assets/images/icons/rainbow-icon.svg";
import WalletConnectIcon from "../assets/images/icons/walletconnect-icon.svg";
import { constants } from "../helpers";

/**
 * Shortens an Ethereum address by showing a prefix and suffix.
 * 
 * @param {string} address - The full Ethereum address.
 * @returns {string} - The shortened Ethereum address.
 */
export function shortenAddress(address) {
  const prefixLength = 6; // Number of characters to show at the beginning of the address
  const suffixLength = 4; // Number of characters to show at the end of the address

  if (!address) return "";

  const shortenedAddress =
    address.substring(0, prefixLength) +
    "..." +
    address.substring(address.length - suffixLength);

  return shortenedAddress;
}

/**
 * Gets the wallet icon based on the wallet identifier.
 * 
 * @param {string} id - The wallet identifier.
 * @returns {React.Component} - The wallet icon component.
 */
export function getWalletIcon(id) {
  switch (id) {
    case constants.WALLET.ID.META_MASK:
      return MetaMaskIcon;
    
    case constants.WALLET.ID.COINBASE_WALLET:
      return CoinbaseIcon;
    
    case constants.WALLET.ID.RAINBOW:
      return RainbowIcon;
    
    case constants.WALLET.ID.WALLET_CONNECT:
            return WalletConnectIcon;

    default:
      return null;
  }
}
