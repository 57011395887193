const regex = {
  USERNAME: /^[a-zA-Z0-9!@#$%^&*()_+~`|\\{}[\]:;'"<>,./?]{8,16}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PHONE_NUMBER: /^\d{10}$/,
  NAME_LENGTH:25,
  DESCRIPTION_LENGTH:/^\W*(\w+(\W+|$)){1,250}$/,
  REFERRAL_CODE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$&%]{8,16}$/,
  TITLE_LEGTH:/^\W*(\w+(\W+|$)){1,10}$/,
  SUB_TITLE:/^\W*(\w+(\W+|$)){1,50}$/,
};

export default regex;
