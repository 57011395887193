import { useEffect } from 'react';

import { setIsPageRefresh } from '../../redux/features/commonSlice';
import { useAppDispatch, useAppSelector } from './redux';

function usePageRefresh(fetchData) {
  const dispatch = useAppDispatch();
  const { isPageRefresh } = useAppSelector((state) => state.common);

  useEffect(() => {
    if (isPageRefresh) {
      fetchData();
      setTimeout(() => {
        dispatch(setIsPageRefresh(false));
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageRefresh]);
}

export default usePageRefresh;
